import React, { useState, useEffect } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap';
import PieChart from './charts/PieChart';
import DoughnutChart from './charts/DoughnutChart';
import WhatsAppAPI from '../api/WhatsAppAPI';
import { Link } from 'react-router-dom';

const Home = () => {
  const [leadCount, setLeadCount] = useState("");
  const [groupCount, setGroupCount] = useState("");
  const [accountCount, setAccountCount] = useState("");
  const [contactCount, setContactCount] = useState("");
  const [autoResponseMsgCount, setAutoResponseMsgCount] = useState(0);
  const [templateCount, setTemplateCount] = useState(0);

  useEffect(() => {
    async function init() {

      // const fetchAccountCount = await WhatsAppAPI.fetchAccountCount();
      // const fetchContactCount = await WhatsAppAPI.fetchContactCount();
      const fetchLeadCount = await WhatsAppAPI.fetchLeadCount();
      const fetchallActiveGroups = await WhatsAppAPI.fetchallActiveGroups();
      const result = await WhatsAppAPI.getResponseMessageData();
      if (result.success) {
        setAutoResponseMsgCount(result.records.length);
      }

      const resultTemp = await WhatsAppAPI.getAllTemplates();
      if (!result.error) {
        setTemplateCount(resultTemp?.data?.length)
      }

      // setAccountCount(fetchAccountCount.total);
      // setContactCount(fetchContactCount.total);
      setLeadCount(fetchLeadCount.total);
      setGroupCount(fetchallActiveGroups.total);

    }

    init();
  }, []);

  console.log('autoResponseMsgCount', autoResponseMsgCount)

  return (
    <>
      <Container className='mt-5'>
        <Row className='mx-5 text-center g-0'>
          <Col lg={12} xs={12} sm={12}>
            <div className=' text-center p-2' style={{ height: '40px', backgroundColor: '#ffffff', borderRadius: '5px' }}>
              <span className='fw-semibold p-1' style={{ color: '#605C68', fontSize: 'large' }}>
                Dashboard
              </span>
            </div>
          </Col>
        </Row>
      </Container>



      <Container>
        <Row className='mx-5 text-center g-0'>
          <Col lg={12} xs={12} sm={12}>
            <Row>
              <Col lg={3} sm={6} xs={12}>
                <Link to="/leads" className='text-decoration-none text-reset'>
                  <div className="p-3 d-flex align-items-center my-3 rounded-1" style={{ backgroundColor: 'white', borderLeft: '4px solid #00ad5b' }}>
                    <span className="fa-stack fa-2x">
                      <i className="fa-solid fa-circle fa-stack-2x" style={{ color: '#00ad5b' }}></i>
                      <i className="fa-solid fa-bolt fa-stack-1x" style={{ color: 'white', fontSize: '2rem' }}></i>
                    </span>
                    <div className="flex-grow-1 text-start ms-2">
                      <h6 className="text-muted mb-1">Total Leads</h6>
                      <h1 className='mb-0 d-inline '>{leadCount}</h1>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col lg={3} sm={6} xs={12}>
                <Link to="/groups" className='text-decoration-none text-reset'>
                  <div className="p-3 d-flex align-items-center my-3 rounded-1" style={{ backgroundColor: 'white', borderLeft: '4px solid #239dd1' }}>
                    <span className="fa-stack fa-2x">
                      <i className="fa-solid fa-circle fa-stack-2x" style={{ color: '#239dd1' }}></i>
                      <i className="fa-solid fa-user-group fa-stack-1x" style={{ color: 'white', fontSize: '2rem' }}></i>
                    </span>
                    <div className="flex-grow-1 text-start ms-2">
                      <h6 className="text-muted mb-1">Active Groups</h6>
                      <h1 className='mb-0 d-inline '>{groupCount}</h1>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col lg={3} sm={6} xs={12}>
                <Link to="/whatsapp_template" className='text-decoration-none text-reset'>
                  <div className="p-3 d-flex align-items-center my-3 rounded-1" style={{ backgroundColor: 'white', borderLeft: '4px solid #d3761f' }}>
                    <span className="fa-stack fa-2x">
                      <i className="fa-solid fa-circle fa-stack-2x" style={{ color: '#d3761f' }}></i>
                      <i className="fa-solid fa-plus fa-stack-1x" style={{ color: 'white', fontSize: '2rem' }}></i>
                    </span>
                    <div className="flex-grow-1 text-start ms-2">
                      <h6 className="text-muted mb-1">Total Templates</h6>
                      <h1 className='mb-0 d-inline text-center'>{templateCount}</h1>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col lg={3} sm={6} xs={12}>
                <Link to="/response_message" className='text-decoration-none text-reset'>
                  <div className="p-3 d-flex align-items-center my-3 rounded-1" style={{ backgroundColor: 'white', borderLeft: '4px solid #debf31' }}>
                    <span className="fa-stack fa-2x">
                      <i className="fa-solid fa-circle fa-stack-2x" style={{ color: '#debf31' }}></i>
                      <i className="fa-solid fa-message fa-stack-1x" style={{ color: 'white', fontSize: '2rem' }}></i>
                    </span>
                    <div className="flex-grow-1 text-start ms-2">
                      <h6 className="text-muted mb-1">Auto Response Message</h6>
                      <h1 className='mb-0 d-inline '>{autoResponseMsgCount}</h1>
                    </div>
                  </div>
                </Link>
              </Col>

            </Row>
          </Col>
        </Row>
      </Container>




      <Container>
        <Row className='mx-5 text-center g-0'>
          <Col lg={12} xs={12} sm={12}>
            <Row>
              <Col lg={6} xs={12} sm={12}>
                <Card className='h-100 mb-3' style={{ border: "none" }}>
                  <Card.Title className="text-center mt-2">Campaign</Card.Title>
                  <div style={{ height: "300px" }}>
                    <PieChart />
                  </div>
                </Card>
              </Col>

              <Col lg={6} xs={12} sm={12}>
                <Card className='h-100 mb-3' style={{ border: "none" }}>
                  <Card.Title className="text-center mt-2">WhatsApp Templates</Card.Title>
                  <div style={{ height: "300px" }}>
                    <DoughnutChart />
                  </div>
                </Card>
              </Col>
            </Row>
          </Col>

        </Row>
      </Container>

    </>
  )
}

export default Home
