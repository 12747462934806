/** @format */
import Sidebar from "./Sidebar"
import Header from "./Header";
import { Outlet } from "react-router-dom";
import Footer from "../Footer";
// import ChatBot from "../chatbot/ChatBot";
// import WhatsappChatbot from "../chatbot/WhatsappChatbot";

const Main = ({ socket }) => {
  return (
    <div className="wrapper">
      <Sidebar />
      <div id="content">
        <Header socket={socket} />
        <Outlet />
        {/* <ChatBot /> */}
        {/* <WhatsappChatbot socket={socket} /> */}
        <Footer />
      </div>
    </div>

  );
};

export default Main;