import { useState } from 'react';
import * as constants from '../constants/CONSTANT';
const authApi = {
  async login(email, password) {
    let response = await fetch(constants.API_BASE_URL + "/api/auth/login", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    });

    const result = await response.json();
    if (result.success) {
      localStorage.setItem("token", result.authToken);
    }
    return result;
  },

  async fetchMyImage() {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/auth/myimage",
      {
        method: "GET",
        //mode: "cors",

        headers: {
          "Authorization": token
        }
      }
    );
    if (response.status === 200) {
      const fileBody = await response.blob();
      return fileBody;
    } else {
      return null;
    }

  },

  async fetchUserImage(userid) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/auth/userimage/" + userid,
      {
        method: "GET",
        //mode: "cors",

        headers: {
          "Authorization": token
        }
      }
    );
    const fileBody = await response.blob();
    return fileBody;
  },

  logout() {
    localStorage.removeItem("token");
    window.location.href = '/login';
  },

  async bs() {
    return "yes";
  },

}

export default authApi
