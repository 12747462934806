import "./App.css";
import "./Sidebar.css"
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import Home from "./components/Home";
import React, { useEffect, useState } from "react";
// import Alert from 'react-bootstrap/Alert';
// import MiniSidebar from "./components/MiniSidebar";
import * as constants from './constants/CONSTANT';

// import PubSub from 'pubsub-js';
import { Alert, Toast, ToastContainer } from "react-bootstrap";
// import UserList from "./components/UserList";
// import UserView from "./components/UserView";
import { UserAdd, UserList, UserTracking, UserView } from "./components/user";
import jwt_decode from "jwt-decode";
import io from "socket.io-client";
import Main from "./components/layout/Main";

import WhatsAppMessenger from "./components/whatsapp_messenger/WhatsAppMessenger";
import WhatsAppSetting from "./components/whatsapp_setting/WhatsAppSetting";
import { AccountAdd, Account, AccountView } from "./components/account"
import { Contacts, ContactAdd, ContactView } from "./components/contacts";
import { Campaign, CampaignAdd, CampaignView } from "./components/campaign";
import { Templates, TemplateAdd } from "./components/whatsapp_template";
import { Groups, GroupView } from "./components/groups";


// Added by shivani start
import LeadList from "./components/leads/LeadList";
import LeadView from "./components/leads/LeadView";
import LeadEdit from "./components/leads/LeadEdit";
// Added by shivani end
import EditProfile from "./components/EditProfile";
import ResponseMessage from "./components/response_message/ResponseMessage";
// import RazorPay from "./components/rozerpay/RazorPay";


const ENDPOINT = 'https://api.indicrm.io/' || 'http://localhost:3003';
// const ENDPOINT = 'https://api.indicrm.io/';

function App() {

  const [modalShow, setModalShow] = useState(false);
  const [title, setTitle] = useState('Confirmation');
  const [message, setMessage] = useState('');
  const [variant, setVariant] = useState('');
  const [userInfo, setUserInfo] = useState({});
  const [permissions, setPermissions] = useState();
  const [connectedSocket, setConnectedSocket] = useState();
  // const location = useLocation();

  const mySubscriber = (msg, data) => {
    switch (msg) {
      case 'RECORD_SAVED_TOAST':
        setTitle(data.title);
        setMessage(data.message);
        setModalShow(true);
        setVariant('success')
        break;
      case 'RECORD_ERROR_TOAST':
        setTitle(data.title);
        setMessage(data.message);
        setModalShow(true);
        setVariant('danger')
        break;
      default:
        break;
    }
  };


  useEffect(() => {
    try {
      const token = localStorage.getItem('token');
      if (token) {
        let user = jwt_decode(token);
        setUserInfo(user);
        const perm = user.permissions.map((obj) => obj.name).join(';');
        setPermissions(perm);

        let socket = io(ENDPOINT, {
          path: '/ibs/socket.io',
          transports: ['websocket'],
          reconnection: true,
          // reconnectionAttempts: 5,
          // reconnectionDelay: 1000,
        });

        socket.on("connect", () => {
          console.log('Socket connected:', socket.id);
          socket.emit("setup", user);
          setConnectedSocket(socket);
        });

        socket.on("connected", () => {
          console.log('Socket setup complete');
        });

        socket.on("receivedwhatsappmessage", (item) => {
          console.log('#Received WhatsApp item:', item);
          console.log('##socket', socket)
        });

        socket.on("disconnect", (reason) => {
          console.log('Socket disconnected:', reason);
        });

        socket.on("connect_error", (err) => {
          console.error('Connection error:', err);
        });

        setConnectedSocket(socket);

        return () => {
          if (connectedSocket) {
            connectedSocket.disconnect();
            console.log('Socket disconnected on cleanup');
          }
        }
      } else {
        console.log('No token found');
      }
    } catch (error) {
      console.error('Error in useEffect:', error);
    }
  }, [localStorage.getItem('token')]);



  return (
    <>
      <ToastContainer className="p-3" position='top-center'>
        <Toast show={modalShow} onClose={() => setModalShow(false)} delay={3000} bg={variant} className="text-white" autohide>

          {variant === 'success' ?
            <div className="p-1 m-1" style={{ backgroundColor: '#198754', color: 'white' }}  >
              <i className="fa-regular fa-circle-check text-white mx-2"></i>
              <strong className="me-auto">{title}</strong>
              <i className="fa-solid fa-xmark text-white float-right" style={{ float: 'right' }} role='button' onClick={() => setModalShow(false)}></i>
            </div>
            :
            <div className="p-1 m-1" style={{ backgroundColor: '#DC3545', color: 'white' }}  >
              <i className="fa-regular fa-circle-check text-white mx-2"></i>
              <strong className="me-auto">{title}</strong>
              <i className="fa-solid fa-xmark text-white float-right" style={{ float: 'right' }} role='button' onClick={() => setModalShow(false)}></i>
            </div>
          }

          <Toast.Body>{message instanceof Array ? message.map((item) => {
            return <span>{item.msg}</span>
          }) : message instanceof Object ? <span>{message.detail}</span> : <span>{message}</span>}</Toast.Body>
        </Toast>
      </ToastContainer>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />

          <Route path="/" element={<Main socket={connectedSocket} />}>
            <Route index element={<Home />} />


            {/******** Show an User By Id *******/}
            <Route
              path="/users"
              element={
                // permissions && permissions.indexOf(constants.MODIFY_ALL) >= 0 ?
                <UserList />
                //     : <Alert error-alert variant='danger' className="alert error-alert">
                //       <i className="fa-solid fa-triangle-exclamation mx-2"></i>You haven’t have any access
                //     </Alert>
              }
            />

            <Route path="users/e" element={<UserAdd />} />
            <Route path="users/:id/e" element={<UserAdd />} />
            <Route path="users/:id" element={<UserView />} />
            <Route path="/usertracking" element={<UserTracking />} />



            {/******** campaign *******/}
            <Route path="/campaign" element={<Campaign />} />
            <Route path="/campaign/add" element={<CampaignAdd />} />
            <Route path="/campaign/view/:id" element={<CampaignView />} />

            {/******** Template *******/}
            <Route path="/whatsapp_template" element={<Templates />} />
            <Route path="/whatsapp_template/add" element={<TemplateAdd />} />

            <Route path="/whatsapp_messenger" element={<WhatsAppMessenger socket={connectedSocket} />} />
            <Route path="/whatsapp_setting" element={<WhatsAppSetting />} />

            {/******** Contact *******/}
            <Route path="/contacts" element={<Contacts />} />
            <Route path="/contacts/e" element={<ContactAdd />} />
            {/* <Route path="/contacts/e" element={permissions && (permissions.indexOf(constants.MODIFY_ALL) >= 0) ? <ContactAdd /> : 'You have no permission to access account'} /> */}
            <Route path="/contacts/view/:id" element={<ContactView socket={connectedSocket} />} />


            {/******** Account *******/}
            <Route path="/accounts"
              element={
                // permissions && (permissions.indexOf(constants.VIEW_ACCOUNT) >= 0 || permissions.indexOf(constants.MODIFY_ALL) >= 0) ?
                <Account />
                // :
                // <Alert error-alert variant='danger' className="alert error-alert">
                //   <i class="fa-solid fa-triangle-exclamation mx-2"></i>You haven’t have any access
                // </Alert>
              }
            />
            <Route path="accounts/:id/e" element={<AccountAdd />} />
            <Route path="accounts/e" element={<AccountAdd />} />
            <Route path="accounts/:id" element={<AccountView />} />

            <Route path="/response_message" element={<ResponseMessage />} />


            {/* // Added by shivani start */}
            <Route path="/leads" element={<LeadList />} />
            <Route path="leads/:id" element={<LeadView />} />
            <Route path="leads/e" element={<LeadEdit />} />
            <Route path="leads/:id/e" element={<LeadEdit />} />

            <Route path="/myprofile" element={<EditProfile />} />

            <Route path="/groups" element={<Groups />} />
            <Route path="/groups/:groupId" element={<GroupView />} />


            {/*  // Added by shivani end */}


            {/* <Route path="/razorpay" element={<RazorPay />} /> */}
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
