import jwt_decode from "jwt-decode";

const helper = {
    checkPermission(perm) {
        let userInfo = jwt_decode(localStorage.getItem('token'));

        if(!userInfo.permissions)
            return false;
        return userInfo.permissions.some(function(el) {
            return el.name === perm;
          }); 
    },


    //added by moin : 13-07-2023
    generateDescriptionHTML(tasks){
        tasks.forEach((item, index) => {
            const str = item.description;
            const parts = str.split(") ");
            if (item.description.includes('@')) {
              const nameRegex = /@\[(.*?)\]/;
              if (parts.length >= 2) {
                let msgarry = [];
                for (let st of parts) {
                  let fullmsg = st;
                  const lastmsg = st.charAt(st.length - 1);
                  if (lastmsg !== ')') {
                    fullmsg = st + ')';
                  }
                  const regex = /\((.*?)\)/;
                  const match1 = fullmsg.match(regex);
                  const userId = match1 ? match1[1] : '';
                  const match = fullmsg.match(nameRegex);
                  const name = match ? match[1] : '';
                  const modifiedSentence = fullmsg.replace(nameRegex, `<a href="/users/${userId}" style="color:#0d6efd">${name}</a>`);
                  const cleanedSentence = modifiedSentence.replace(/\(.*?\)|@\[|\]|\[|\]/g, '');
                  msgarry.push(cleanedSentence);
                }
                let finalMsg = msgarry.join(' ').replace(')', '');
                item.description = finalMsg
              } else {
                const regex = /\((.*?)\)/;
                const match1 = item.description.match(regex);
                const userId = match1 ? match1[1] : '';
                const match = item.description.match(nameRegex);
                const name = match ? match[1] : '';
                const modifiedSentence = item.description.replace(nameRegex, `<a href="/users/${userId}" style="color:#0d6efd">${name}</a>`);
                const cleanedSentence = modifiedSentence.replace(/\(.*?\)|@\[|\]|\[|\]/g, '');
                item.description = cleanedSentence;
              }
            }
          });
    }
}

export default helper